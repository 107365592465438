import CheckboxExample from "../../components/styleguideComponents/CheckboxExample";
import Layout from "../../layout/Layout";
import { ComponentContainer } from ".";

const Radiobuttons = () => (
  <Layout>
    <div style={{ margin: 16 }}>
      <ComponentContainer>
        <CheckboxExample />
      </ComponentContainer>
    </div>
  </Layout>
);

export default Radiobuttons;
