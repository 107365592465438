import HeaderExample from "../../components/styleguideComponents/HeaderExample";
import MinifiedHeaderExample from "../../components/styleguideComponents/MinifiedHeaderExample";
import Layout from "../../layout/Layout";
import { ComponentContainer } from ".";

const Headers = () => (
  <Layout>
    <div style={{ margin: 16 }}>
      <ComponentContainer>
        <HeaderExample />
      </ComponentContainer>
      <ComponentContainer>
        <MinifiedHeaderExample />
      </ComponentContainer>
    </div>
  </Layout>
);

export default Headers;
