import { Card, CardContent, List, ListItem, ListItemText } from "@mui/material";
import { FunctionComponent } from "react";
import { Dimensions } from "../..";

interface Props {
  dimensions: Dimensions;
}

const DimensionsExplanationButton: FunctionComponent<Props> = ({
  dimensions,
}) => (
  <Card style={{ maxWidth: 700 }}>
    <CardContent>
      <List>
        {Object.keys(dimensions).map((key) => (
          <ListItem key={key}>
            <ListItemText
              primary={`${key}: ${dimensions[key].value}`}
              primaryTypographyProps={{ variant: "h4" }}
              secondaryTypographyProps={{ variant: "body1" }}
              secondary={`${dimensions[key].description}`}
            />
          </ListItem>
        ))}
      </List>
    </CardContent>
  </Card>
);

export default DimensionsExplanationButton;
