import { BrowserRouter, Route } from "react-router-dom";
import Colors from "./pages/Colors";
import Icons from "./pages/Icons";
import Typography from "./pages/Typography";
import Components from "./pages/Components";
import Spaces from "./pages/Spaces";
import Home from "./pages/Home";
import Buttons from "./pages/Components/Buttons";
import Textfields from "./pages/Components/Textfields";
import Checkboxes from "./pages/Components/Checkboxes";
import Alerts from "./pages/Components/Alerts";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import HeadersPage from "./pages/Components/Headers";
import Footers from "./pages/Components/Footers";
import Logos from "./pages/Logos";
import NewIcons from "./pages/NewIcons";

export const ROUTES = {
  root: "/",
  colors: "/colors",
  icons: "/icons",
  newIcons: "/new-icons",
  typography: "/typography",
  spaces: "/spaces",
  components: "/components",
  buttons: "/components/buttons",
  textFields: "/components/textfields",
  checkboxes: "/components/checkboxes",
  alerts: "/components/alerts",
  footers: "/compnents/footers",
  headers: "/components/headers",
  logos: "/logos",
};

const getHomeItem = (pathname: string) => <>example</>;
const getComponentsItem = (pathname: string) => <>breadcrumb</>;

export const getBreadcrumbItems = ({ pathname }: { pathname: string }) => [
  getHomeItem(pathname),
  getComponentsItem(pathname),
];

const Routes = () => {
  return (
    <BrowserRouter>
      <Route
        exact
        path={ROUTES.root}
        component={withAuthenticationRequired(Home)}
      />
      <Route
        exact
        path={ROUTES.colors}
        component={withAuthenticationRequired(Colors)}
      />
      <Route
        exact
        path={ROUTES.icons}
        component={withAuthenticationRequired(Icons)}
      />
      <Route
        exact
        path={ROUTES.newIcons}
        component={withAuthenticationRequired(NewIcons)}
      />
      <Route
        exact
        path={ROUTES.typography}
        component={withAuthenticationRequired(Typography)}
      />
      <Route
        exact
        path={ROUTES.components}
        component={withAuthenticationRequired(Components)}
      />
      <Route
        exact
        path={ROUTES.buttons}
        component={withAuthenticationRequired(Buttons)}
      />
      <Route
        exact
        path={ROUTES.textFields}
        component={withAuthenticationRequired(Textfields)}
      />
      <Route
        exact
        path={ROUTES.checkboxes}
        component={withAuthenticationRequired(Checkboxes)}
      />
      <Route
        exact
        path={ROUTES.alerts}
        component={withAuthenticationRequired(Alerts)}
      />
      <Route
        exact
        path={ROUTES.headers}
        component={withAuthenticationRequired(HeadersPage)}
      />
      <Route
        exact
        path={ROUTES.footers}
        component={withAuthenticationRequired(Footers)}
      />
      <Route
        exact
        path={ROUTES.spaces}
        component={withAuthenticationRequired(Spaces)}
      />
      <Route
        exact
        path={ROUTES.logos}
        component={withAuthenticationRequired(Logos)}
      />
    </BrowserRouter>
  );
};

export default Routes;
