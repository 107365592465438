import { FunctionComponent, useEffect, useState } from "react";
import { iconsArray } from "../../pages/Icons";
import {
  Alert,
  MenuItem,
  Snackbar,
  styled,
  TextField,
  useTheme,
} from "@mui/material";
import usePrevious from "../../hooks/usePrevious";
import { Icon } from "@advantest/ui-components";
import { IconConst } from "@advantest/ui-components";

export interface CustomizableStyleguideIconProps {
  fill?: string;
  viewBox?: string;
  height?: string;
  width?: string;
}

export type StyleguideIconProps = {
  d: string;
} & CustomizableStyleguideIconProps;

const VariablesContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const MainIconExample: FunctionComponent = () => {
  const initialIcon = "Cart";
  const initialCategory = "Action";

  const theme = useTheme();
  const initialIconProps = IconConst(theme)[initialCategory][initialIcon];

  const [selectedIcon, setSelectedIcon] = useState<string>(
    `${initialCategory}-${initialIcon}`
  );
  const [iconProps, setIconProps] =
    useState<StyleguideIconProps>(initialIconProps);

  const previousIcon = usePrevious(selectedIcon);

  useEffect(() => {
    if (selectedIcon !== previousIcon) {
      setIconProps({
        ...(IconConst(theme) as any)[selectedIcon.split("-")[0]][
          `${selectedIcon.split("-")[1]}`
        ],
        fill:
          (IconConst(theme) as any)[selectedIcon.split("-")[0]][
            `${selectedIcon.split("-")[1]}`
          ].fill || "",
        viewBox:
          (IconConst(theme) as any)[selectedIcon.split("-")[0]][
            `${selectedIcon.split("-")[1]}`
          ].viewBox || "",
        height:
          (IconConst(theme) as any)[selectedIcon.split("-")[0]][
            `${selectedIcon.split("-")[1]}`
          ].height || "",
        width:
          (IconConst(theme) as any)[selectedIcon.split("-")[0]][
            `${selectedIcon.split("-")[1]}`
          ].width || "",
      });
    }
  }, [selectedIcon, previousIcon]);
  const [alertOpen, setAlertOpen] = useState(false);

  return (
    <div style={{ padding: 16 }}>
      <VariablesContainer>
        <TextField
          label="icon"
          value={selectedIcon}
          onChange={(e) => setSelectedIcon(e.target.value)}
          sx={{ width: 300, marginBottom: 1 }}
          select
        >
          {iconsArray.map(({ category, label }) => (
            <MenuItem
              key={`${category}-${label}`}
              value={`${category}-${label}`}
            >{`${category}-${label}`}</MenuItem>
          ))}
        </TextField>
        <TextField
          label="fill"
          value={iconProps.fill}
          onChange={(e) => setIconProps({ ...iconProps, fill: e.target.value })}
          sx={{ width: 300, marginBottom: 1 }}
        />
        <TextField
          label="height"
          value={iconProps.height}
          onChange={(e) =>
            setIconProps({ ...iconProps, height: e.target.value })
          }
          sx={{ width: 300, marginBottom: 1 }}
        />
        <TextField
          label="width"
          value={iconProps.width}
          onChange={(e) =>
            setIconProps({ ...iconProps, width: e.target.value })
          }
          sx={{ width: 300, marginBottom: 1 }}
        />
        <TextField
          label="viewBox"
          value={iconProps.viewBox}
          onChange={(e) =>
            setIconProps({ ...iconProps, viewBox: e.target.value })
          }
          sx={{ width: 300, marginBottom: 1 }}
        />
      </VariablesContainer>
      <div style={{ padding: 16 }}>
        <Icon {...iconProps} />
      </div>

      <Snackbar
        open={alertOpen}
        autoHideDuration={2000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert severity="info" variant="filled">
          Copied code of {selectedIcon} to clipboard.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MainIconExample;
