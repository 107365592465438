import NewIcon from "../components/styleguideComponents/NewIcon";
import { HorizontalContainer } from "../containers";
import Layout from "../layout/Layout";

const newIcons: string[] = [
  "AdvantestGlassWall",
  "AdvantestLicensingPortal",
  "AdvantestSmartInstall3",
  "AdvantestSoftwareCenter",
  "AdvantestTdc",
  "myAdvantest",
  "myAdvantestAnalysis",
  "myAdvantestCSWP",
  "myAdvantestEntitlements",
  "myAdvantestHelpdesk",
  "myAdvantestPim",
  "myAdvantestShop",
  "myAdvantestStyleGuide",
  "SupportEVA100",
  "SupportProbo",
  "SupportV93000",
  "SupportT2000",
];

const NewIcons = () => {
  return (
    <Layout>
      <HorizontalContainer>
        {newIcons.map((iconName) => (
          <NewIcon key={iconName} iconName={iconName} />
        ))}
      </HorizontalContainer>
    </Layout>
  );
};

export default NewIcons;
