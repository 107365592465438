import IconItem from "../components/styleguideComponents/IconItem";
import Layout from "../layout/Layout";
import { HorizontalContainer } from "../containers";
import { styled, Typography } from "@mui/material";
import MainIconExample from "../components/icons/MainIconExample";

export type IconCategory = "Action" | "Menu" | "SI";
export type IconItemType = {
  label: string;
  category: "Action" | "Menu" | "SI";
};

const categories: IconCategory[] = ["Action", "Menu", "SI"];

const VerticalContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
});

export const iconsArray: IconItemType[] = [
  { label: "Search", category: "Menu" },
  { label: "NotificationBell", category: "Menu" },
  { label: "Global", category: "Menu" },
  { label: "Cart", category: "Action" },
  { label: "Profile", category: "Menu" },
  { label: "ArrowDown", category: "Menu" },
  { label: "Apps", category: "Menu" },
  { label: "Share", category: "Action" },
  { label: "CloseMd", category: "Action" },
  { label: "Follow", category: "Action" },
  { label: "Calendar", category: "Action" },
  { label: "AddOutline", category: "Action" },
  { label: "CloseSm", category: "Action" },
  { label: "Avatar", category: "Action" },
  { label: "Const", category: "Action" },
  { label: "DatabaseAdministrator", category: "Action" },
  { label: "DeleteDocument", category: "Action" },
  { label: "Delete", category: "Action" },
  { label: "Edit", category: "Action" },
  { label: "Export", category: "Action" },
  { label: "Facebook", category: "Action" },
  { label: "In", category: "Action" },
  { label: "Order", category: "Action" },
  { label: "Out", category: "Action" },
  { label: "Pdf", category: "Action" },
  { label: "Playlist", category: "Action" },
  { label: "Reboot", category: "Action" },
  { label: "Report", category: "Action" },
  { label: "Success", category: "Action" },
  { label: "Twitter", category: "Action" },
  { label: "Update", category: "Action" },
  { label: "Wallet", category: "Action" },
  { label: "Warning", category: "Action" },
  { label: "Start", category: "SI" },
  { label: "ArrowRight", category: "SI" },
  { label: "Dashboard", category: "SI" },
  { label: "Download", category: "SI" },
  { label: "Filter", category: "SI" },
  { label: "Home", category: "SI" },
  { label: "Information", category: "SI" },
  { label: "Layout", category: "SI" },
  { label: "Pause", category: "SI" },
  { label: "Question", category: "SI" },
  { label: "Stop", category: "SI" },
  { label: "System", category: "SI" },
  { label: "Upload", category: "SI" },
];

const Icons = () => {
  return (
    <Layout>
      <MainIconExample />
      <div style={{ margin: 16 }}>
        {categories.map((category) => (
          <>
            <Typography variant="h2">{category}</Typography>
            <HorizontalContainer>
              {iconsArray
                .filter((icon) => icon.category === category)
                .map(({ label, category }) => (
                  <VerticalContainer>
                    <IconItem
                      key={label}
                      label={label}
                      category={category}
                    ></IconItem>
                  </VerticalContainer>
                ))}
            </HorizontalContainer>
          </>
        ))}
      </div>
    </Layout>
  );
};

export default Icons;
