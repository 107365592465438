import { Header, headerIconButtonHover, Icon } from "@advantest/ui-components";
import { useHistory } from "react-router-dom";
import Link from "@mui/material/Link";
import { getBreadcrumbItems } from "../Routes";
import { getHeaderDimensions } from "../getHeaderDimensions";
import {
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { useAuth0 } from "@auth0/auth0-react";

const StyleguideHeader = ({ isMinified }: { isMinified?: boolean }) => {
  const router = useHistory();

  const { logout } = useAuth0();

  const headerDimensions = getHeaderDimensions(!!isMinified);

  const menuItemSX = {
    typography: headerDimensions.menuTextVariant.value,
    textDecoration: "none",
    textTransform: "uppercase",
    cursor: "pointer",
    marginLeft: headerDimensions.spaceBetweenMenuItems.value,
    "&:hover": {
      fontWeight: 400,
    },
  };

  return (
    <Header
      logo={
        <img
          src="/ADVANTEST_logo_original.svg"
          alt="Advanest-logo"
          height="58px"
          width="200px"
        />
      }
      servicesMenuItems={[
        {
          service: {
            title: "Service 1",
            sequence: 1,
            category: { sequence: 1, title: "Category 1" },
            href: "/",
          },
        },
      ]}
      menuItems={[
        <Link sx={menuItemSX}>MENU 1</Link>,
        <Link sx={menuItemSX}>MENU 2</Link>,
      ]}
      headerSecondRow={
        !isMinified
          ? {
              applicationTitle: "myAdvantest Portal",
              breadcrumbItems: getBreadcrumbItems({
                pathname: router.location.pathname,
              }),
            }
          : undefined
      }
      actionComponents={[
        <PopupState variant="popover" popupId="profile-popover" key="1">
          {(popupState) => (
            <>
              <IconButton
                {...bindTrigger(popupState)}
                sx={{
                  "&:focus-visible": {
                    border: "1px solid black",
                  },
                  "&:hover": headerIconButtonHover,
                }}
                disableRipple
              >
                <Icon category="Menu" label="Profile" />
              </IconButton>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <List>
                  <ListItem sx={{ cursor: "pointer" }} onClick={() => logout()}>
                    <ListItemText primary={"logout"} />
                  </ListItem>
                </List>
              </Popover>
            </>
          )}
        </PopupState>,
      ]}
    />
  );
};

export default StyleguideHeader;
