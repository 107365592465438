import { useTheme } from "@mui/material";
import CodeExample from "../CodeExample";
import DimensionsExample from "../DimensionsExample";
import StyleguideHeader from "../../StyleguideHeader";
import { getHeaderDimensions } from "../../../getHeaderDimensions";

export const PADDING = "16px !important";

const MinifiedHeaderExamplePerDevice = () => {
  return (
    <div style={{ marginBottom: 40 }}>
      <div
        style={{
          width: "1200px",
          border: "1px solid black",
          height: 250,
          marginBottom: 24,
        }}
      >
        <StyleguideHeader isMinified />
      </div>
      <CodeExample label="MinifiedHeader" />
      <DimensionsExample dimensions={getHeaderDimensions(true)} />
    </div>
  );
};

export default MinifiedHeaderExamplePerDevice;
