import { styled, Typography } from "@mui/material";
import FooterExamplePerDevice from "./FooterExamplePerDevice";

const Container = styled("div")(() => ({
  width: 1000,
}));

const FooterExample = () => {
  return (
    <Container>
      <Typography variant="h2" style={{ marginBottom: 24 }}>
        Footer
      </Typography>
      <FooterExamplePerDevice />
    </Container>
  );
};

export default FooterExample;
