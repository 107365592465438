import { getHeaderHeight } from "@advantest/ui-components";
import { Dimensions } from ".";
import { DIN2014DemiExplanation } from "./pages/Typography";

const PADDING = "16px";

// constants that are coming from the ui-components and needs to be manually checked
const applicationTitleFont = {
  description: DIN2014DemiExplanation,
  value: "DIN 2014 Demi, 18px",
};
const firstRowHeight = {
  description: "Height of the first row in the header.",
  value: "58px",
};
const screenRightToFirstButtonPadding = {
  value: PADDING,
  description:
    "The distance between the right side of the screen and the first item from the left in the header.",
};

const spaceBetweenMenuItems = {
  value: "32px",
  description: "Space between menu items in the first row.",
};

const logoWidth = {
  value: "200px",
  description:
    "The main Advantest logo has 200px width. The Application logo together with the text should not exceed this value as well.",
};

export const getHeaderDimensions = (isMinified: boolean): Dimensions => ({
  applicationTitleFont,
  firstRowHeight,
  fullHeight: {
    description: `Full height of the header'.`,
    value: `${getHeaderHeight(isMinified, "lg")}px`,
  },
  screenRightToFirstButtonPadding,
  menuTextVariant: {
    value: "body1" as "body1",
    description:
      "The typography variant of any menu item and logout components.",
  },
  spaceBetweenMenuItems,
  secondRowHeight: {
    value: "36px !important",
    description: `${getHeaderHeight(isMinified, "lg")}px - ${
      firstRowHeight.value
    }`,
  },
  logoWidth,
});
