const TypographyH3ExampleHtmlCss = `
<style>
  h3 {
    margin: 0;
    color: #263238;
    font-size: 1.424rem;
    font-family: Rubik,sans-serif;
    font-weight: 300;
    line-height: 1.167;
    letter-spacing: 0;
  }
</style>
<h3>
Title 3
</h3>`;

export default TypographyH3ExampleHtmlCss;
