import { Card, styled } from "@mui/material";
import CustomCheckbox from "../Checkbox";
import CodeExample from "./CodeExample";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  width: 300,
}));

const CheckboxExample = () => (
  <div>
    <StyledCard>
      <CustomCheckbox label="Check Box" />
      <CustomCheckbox disabled label="Check Box Disabled" />
    </StyledCard>
    <CodeExample label="Checkbox" />
  </div>
);

export default CheckboxExample;
