const TypographyH1ExampleHtmlCss = `<style>
  h1 {
    margin: 0;
    color: #263238;
    font-size: 1.802rem;
    font-family: Rubik,sans-serif;
    font-weight: 300;
    line-height: 1.167;
    letter-spacing: 0;
  }
</style>
<h1>
Title 1
</h1>`;

export default TypographyH1ExampleHtmlCss;
