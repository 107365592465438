import { styled, Typography } from "@mui/material";
import HeaderExamplePerDevice from "./HeaderExamplePerDevice";

const Container = styled("div")(() => ({
  width: 1000,
}));

const HeaderExample = () => {
  return (
    <Container>
      <Typography variant="h2" style={{ marginBottom: 24 }}>
        Header
      </Typography>
      <HeaderExamplePerDevice />
    </Container>
  );
};

export default HeaderExample;
