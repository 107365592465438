import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@mui/material";

import ReleaseNotes, { LATEST } from "../components/ReleaseNotes/ReleaseNotes";

import Layout from "../layout/Layout";

const Home = () => {
  const { user } = useAuth0();
  return (
    <Layout>
      <div style={{ marginLeft: 16 }}>
        {/* <Typography>environment: {getEnvironment()}</Typography> */}
        <Typography style={{ marginBottom: 40 }}>version: {LATEST}</Typography>
        <Typography variant="h2">
          Welcome, {user ? user.given_name : ""}
        </Typography>
        <p>
          For React development{" "}
          <a href="https://www.npmjs.com/package/@advantest/ui-components">
            @advantest/ui-components
          </a>{" "}
          package is published on npm. It is also used by this styleguide.
        </p>
        <ReleaseNotes />
      </div>
    </Layout>
  );
};

export default Home;
