import { useState } from "react";

interface Props {
  version: string;
  releaseDate: string;
  details: string[];
  initialIsOpen?: boolean;
}

const ReleaseNote = ({
  version,
  releaseDate,
  details,
  initialIsOpen,
}: Props) => {
  const [isOpen, setIsOpen] = useState(!!initialIsOpen);

  return (
    <div>
      <p
        onClick={() => setIsOpen(!isOpen)}
        style={{ cursor: "pointer" }}
      >{`${version} - ${releaseDate}`}</p>
      {isOpen && (
        <p>
          <ul>
            {details.map((detail) => (
              <li key={detail.substring(0, 10)}>{detail}</li>
            ))}
          </ul>
        </p>
      )}
    </div>
  );
};

export default ReleaseNote;
