import { styled, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import React, { FunctionComponent } from "react";
import { ExampleType } from "../../codeExamples";
import CodeExample from "./CodeExample";
import ColorItem from "./ColorItem";
import SpaceItem from "./SpaceItem";

interface Props {
  variant?: Variant | "inherit";
  label: string;
  colorLabel: string;
  color: string;
  component?: React.ElementType;
  style?: object;
  spacing: string;
  codeExampleLabel: ExampleType;
  isAbsolute?: boolean;
}

const HorizontalContainer = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  margin: theme.spacing(2),
}));

const VerticalContainer = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: theme.spacing(2),
}));

const ItemsContainer = styled(Typography)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: 400,
});

const TypographyItem: FunctionComponent<Props> = ({
  variant,
  label,
  colorLabel,
  color,
  component,
  style,
  spacing,
  codeExampleLabel,
  isAbsolute,
}) => (
  <VerticalContainer>
    <HorizontalContainer>
      <Typography
        {...(style ? style : {})}
        variant={variant || undefined}
        component={(component || undefined) as any}
      >
        {label}
      </Typography>
      <ItemsContainer>
        <ColorItem
          label={colorLabel}
          color={color}
          secondaryLabel={color}
          arrangement="horizontal"
        />
        <SpaceItem spaceSize={spacing} isRelative={!isAbsolute} />
      </ItemsContainer>
    </HorizontalContainer>
    <CodeExample label={codeExampleLabel} />
  </VerticalContainer>
);

export default TypographyItem;
