import AlertExample from "../../components/styleguideComponents/AlertExample";
import Layout from "../../layout/Layout";
import { ComponentContainer } from "./";

const Alerts = () => (
  <Layout>
    <div style={{ margin: 16 }}>
      <ComponentContainer>
        <AlertExample />
      </ComponentContainer>
    </div>
  </Layout>
);

export default Alerts;
