import React, { FunctionComponent, useState } from "react";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/hljs";
import SyntaxHighlighter from "react-syntax-highlighter";
import {
  Alert,
  Button,
  Link,
  Snackbar,
  styled,
  Typography,
} from "@mui/material";
import { codeExamples, ExampleType, Language } from "../../codeExamples";

interface Props {
  label: ExampleType;
  linksAtTheEnd?: React.ReactNode[];
  description?: string;
  defaultOpen?: Language;
}

const Container = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

export const StyledExpandableButton = styled(Button)(({ theme }: any) => ({
  boxSizing: "border-box",
  padding: theme.spacing(1),
  cursor: "pointer",
  textDecoration: "none !important",
  marginRight: theme.spacing(2),
  marginBottom: theme.spacing(2),
  variant: "contained",
})) as any;

const LinksContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
}));

const VerticalContainer = styled("div")({
  marginTop: 8,
});

const publicLanguageLabels: { [key in Language]: string } = {
  ReactTs: "React (Ts)",
  HtmlCss: "Html/Css",
};

const CodeExample: FunctionComponent<Props> = ({
  label,
  description,
  linksAtTheEnd,
  defaultOpen,
}) => {
  const [languageOpen, setLanguageOpen] = useState<null | Language>(
    defaultOpen || null
  );
  const [alertOpen, setAlertOpen] = useState(false);

  const languages = Object.keys(codeExamples[label]) as Language[];

  return (
    <Container>
      <LinksContainer>
        {languages
          .filter((language) => codeExamples[label][language])
          .map((language) => {
            const isActive = languageOpen === language;

            return (
              <StyledExpandableButton
                key={language}
                color={isActive ? "secondary" : "primary"}
                variant="contained"
                onClick={() =>
                  setLanguageOpen(
                    languageOpen === null
                      ? language
                      : isActive
                      ? null
                      : language
                  )
                }
              >
                {"</>"} {publicLanguageLabels[language]}
              </StyledExpandableButton>
            );
          })}
      </LinksContainer>
      {languageOpen && (
        <VerticalContainer>
          {(description || (linksAtTheEnd && linksAtTheEnd.length > 0)) && (
            <div style={{ marginBottom: 40 }}>
              {description && <Typography>{description}</Typography>}
              <div style={{ display: "flex", flexDirection: "column" }}>
                {linksAtTheEnd && linksAtTheEnd.map((link) => link)}
              </div>
            </div>
          )}
          <Link
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigator.clipboard.writeText(
                codeExamples[label][languageOpen] || ""
              );
              setAlertOpen(true);
            }}
          >
            Copy code
          </Link>
          <SyntaxHighlighter
            language={languageOpen === "ReactTs" ? "react" : "html"}
            style={darcula}
          >
            {codeExamples[label][languageOpen]}
          </SyntaxHighlighter>
        </VerticalContainer>
      )}
      <Snackbar
        open={alertOpen}
        autoHideDuration={2000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert severity="info" variant="filled">
          Copied {languageOpen} code of {label} to clipboard.
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CodeExample;
