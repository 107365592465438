import { Button, SvgIcon, useTheme } from "@mui/material";
import CodeExample from "./CodeExample";
import { IconConst } from "@advantest/ui-components";

const ButtonsExample = () => {
  const theme = useTheme();

  return (
    <div>
      <div>
        <div style={{ marginBottom: 16 }}>
          <Button variant="contained" color="primary">
            primary button
          </Button>
        </div>
        <div style={{ marginBottom: 16 }}>
          <Button variant="contained" color="secondary">
            secondary button
          </Button>
        </div>
        <div style={{ marginBottom: 16 }}>
          <Button variant="contained" color="primary" size="small">
            small button
          </Button>
        </div>
        <div style={{ marginBottom: 16 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={
              <SvgIcon viewBox="-6 -4 26 26">
                <path d={IconConst(theme).Action.Follow.d} />
              </SvgIcon>
            }
          >
            Follow
          </Button>
        </div>
      </div>
      <CodeExample label="Buttons" />
    </div>
  );
};

export default ButtonsExample;
