import { Typography } from "@mui/material";

interface Props {
  applicationFolderName: string;
  logoBaseName: string;
  nrOfLogos: 2 | 3 | 4;
}

const ApplicationLogo = ({
  applicationFolderName,
  logoBaseName,
  nrOfLogos,
}: Props) => {
  return (
    <div style={{ marginTop: "16px" }}>
      <img
        src={`/logos/${applicationFolderName}/${logoBaseName}.svg`}
        style={{ height: "30px", marginLeft: "32px" }}
      />
      <img
        src={`/logos/${applicationFolderName}/${logoBaseName}Tm.svg`}
        style={{ height: "30px", marginLeft: "32px" }}
      />
      {(nrOfLogos == 3 || nrOfLogos === 4) && (
        <img
          src={`/logos/${applicationFolderName}/${logoBaseName}Short.svg`}
          style={{ height: "30px", marginLeft: "32px" }}
        />
      )}
      {nrOfLogos === 4 && (
        <img
          src={`/logos/${applicationFolderName}/${logoBaseName}Multi.svg`}
          style={{ height: "30px", marginLeft: "32px" }}
        />
      )}
      <Typography>{applicationFolderName}</Typography>
    </div>
  );
};

export default ApplicationLogo;
