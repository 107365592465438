import { Typography } from "@mui/material";
import { VerticalContainer } from "../../containers";

interface Props {
  iconName: string;
}

const NewIcon = ({ iconName }: Props) => {
  return (
    <div style={{ marginLeft: "36px", marginTop: "36px" }}>
      <VerticalContainer>
        <img src={`/icons/${iconName}.svg`} height="40px" />
        <Typography>{iconName}</Typography>
      </VerticalContainer>
    </div>
  );
};

export default NewIcon;
