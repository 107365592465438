const TypographyApplicationTitleExampleHtmlCss = `
<style>
  span {
    margin: 0;
    color: #000000;
    font-size: 18px;
    font-family: DIN2014-Demi,sans-serif;
    font-weight: 300;
    line-height: 1.75;
    letter-spacing: 0;
  }
</style>
<span>
Application Title in the header, below the Advantest logo, DIN 2014 Demi font
</span>`;

export default TypographyApplicationTitleExampleHtmlCss;
