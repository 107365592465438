const TypographyH5ExampleHtmlCss = `
<style>
  h5 {
    margin: 0;
    color: #263238;
    font-size: 1.125rem;
    font-family: Rubik,sans-serif;
    font-weight: 300;
    line-height: 1.334;
    letter-spacing: 0;
  }
</style>
<h5>
Title 5
</h5>`;

export default TypographyH5ExampleHtmlCss;
