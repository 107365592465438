import { Button, CardActions, CardContent, Typography } from "@mui/material";
import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";

interface Props {
  title: string;
  path: string;
  media: string;
}

const StyleguideCard: FunctionComponent<Props> = ({ title, path, media }) => {
  const history = useHistory();

  return (
    <div style={{ width: 400, margin: 16, background: "white" }}>
      <CardContent>
        <Typography gutterBottom variant="h5">
          {title}
        </Typography>
      </CardContent>
      <CardActions>
        <Button color="primary" onClick={() => history.push(path)}>
          Learn More
        </Button>
      </CardActions>
    </div>
  );
};

export default StyleguideCard;
