const TypographyBody1ExampleHtmlCss = `
<style>
  p {
    margin: 0;
    color: #263238;
    font-size: 1rem;
    font-family: Rubik,sans-serif;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0;
  }
</style>
<p>
Body 1
</p>`;

export default TypographyBody1ExampleHtmlCss;
