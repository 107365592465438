import TextFieldExample from "../../components/styleguideComponents/TextFieldExample";
import Layout from "../../layout/Layout";
import { ComponentContainer } from ".";

const Textfields = () => (
  <Layout>
    <div style={{ margin: 16 }}>
      <ComponentContainer>
        <TextFieldExample />
      </ComponentContainer>
    </div>
  </Layout>
);

export default Textfields;
