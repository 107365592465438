import { styled } from "@mui/material";
import { HorizontalContainer } from "../../containers";
import Layout from "../../layout/Layout";
import StyleguideCard from "../../components/StyleguideCard";
import { ROUTES } from "../../Routes";

export const ComponentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  margin: theme.spacing(2),
}));

const Components = () => (
  <Layout>
    <div style={{ margin: 16 }}>
      <HorizontalContainer>
        <StyleguideCard
          title="Buttons"
          path={ROUTES.buttons}
          media="/buttons.png"
        />
        <StyleguideCard
          title="Text fields"
          path={ROUTES.textFields}
          media="/textfields.png"
        />
        <StyleguideCard
          title="Check boxes"
          path={ROUTES.checkboxes}
          media="/checkboxes.png"
        />

        <StyleguideCard
          title="Alerts"
          path={ROUTES.alerts}
          media="/alerts.png"
        />
        <StyleguideCard
          title="Headers"
          path={ROUTES.headers}
          media="/headers.png"
        />
        <StyleguideCard
          title="Footers"
          path={ROUTES.footers}
          media="/footers.png"
        />
      </HorizontalContainer>
    </div>
  </Layout>
);

export default Components;
