import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";

const CustomCheckbox: React.FunctionComponent<{
  label: string;
  helperText?: string;
  error?: string;
  disabled?: boolean;
}> = ({ label, helperText, error, disabled }) => {
  return (
    <FormControl sx={{ margin: "0px 0px 0px 8px", height: "72px" }}>
      <FormControlLabel
        control={<Checkbox disabled={!!disabled} />}
        label={label}
      />
      {helperText && (
        <Typography color={error ? "error" : "info"}>{helperText}</Typography>
      )}
    </FormControl>
  );
};

export default CustomCheckbox;
