import TypographyMainExampleHtmlCss from "./TypographyMainExampleHtmlCss";
import TypographyH1ExampleHtmlCss from "./TypographyH1ExampleHtmlCss";
import TypographyH2ExampleHtmlCss from "./TypographyH2ExampleHtmlCss";
import TypographyH3ExampleHtmlCss from "./TypographyH3ExampleHtmlCss";
import TypographyH4ExampleHtmlCss from "./TypographyH4ExampleHtmlCss";
import TypographyH5ExampleHtmlCss from "./TypographyH5ExampleHtmlCss";
import TypographyBody1ExampleHtmlCss from "./TypographyBody1ExampleHtmlCss";
import TypographyBody2ExampleHtmlCss from "./TypographyBody2ExampleHtmlCss";
import TypographyCaptionExampleHtmlCss from "./TypographyCaptionExampleHtmlCss";
import TypographyApplicationTitleExampleHtmlCss from "./TypographyApplicationTitleExampleHtmlCss";

export type ExampleType =
  | "Buttons"
  | "Alert"
  | "Breadcrumbs"
  | "Checkbox"
  | "Footer"
  | "Header"
  | "MinifiedHeader"
  | "Radio"
  | "TextField"
  | "TypographyMain"
  | "TypographyH1"
  | "TypographyH2"
  | "TypographyH3"
  | "TypographyH4"
  | "TypographyH5"
  | "TypographyBody1"
  | "TypographyBody2"
  | "TypographyCaption"
  | "ApplicationTitle";

export type Language = "ReactTs" | "HtmlCss";

export const codeExamples: {
  [type in ExampleType]: { [language in Language]: string | null };
} = {
  Buttons: {
    ReactTs: null,
    HtmlCss: null,
  },
  Alert: {
    ReactTs: null,
    HtmlCss: null,
  },
  Breadcrumbs: {
    ReactTs: null,
    HtmlCss: null,
  },
  Checkbox: {
    ReactTs: null,
    HtmlCss: null,
  },
  Footer: {
    ReactTs: null,
    HtmlCss: null,
  },
  Header: {
    ReactTs: null,
    HtmlCss: null,
  },
  MinifiedHeader: {
    ReactTs: null,
    HtmlCss: null,
  },
  Radio: {
    ReactTs: null,
    HtmlCss: null,
  },
  TextField: {
    ReactTs: null,
    HtmlCss: null,
  },
  TypographyMain: {
    ReactTs: null,
    HtmlCss: TypographyMainExampleHtmlCss,
  },
  TypographyH1: {
    ReactTs: null,
    HtmlCss: TypographyH1ExampleHtmlCss,
  },
  TypographyH2: {
    ReactTs: null,
    HtmlCss: TypographyH2ExampleHtmlCss,
  },
  TypographyH3: {
    ReactTs: null,
    HtmlCss: TypographyH3ExampleHtmlCss,
  },
  TypographyH4: {
    ReactTs: null,
    HtmlCss: TypographyH4ExampleHtmlCss,
  },
  TypographyH5: {
    ReactTs: null,
    HtmlCss: TypographyH5ExampleHtmlCss,
  },
  TypographyBody1: {
    ReactTs: null,
    HtmlCss: TypographyBody1ExampleHtmlCss,
  },
  TypographyBody2: {
    ReactTs: null,
    HtmlCss: TypographyBody2ExampleHtmlCss,
  },
  TypographyCaption: {
    ReactTs: null,
    HtmlCss: TypographyCaptionExampleHtmlCss,
  },
  ApplicationTitle: {
    ReactTs: null,
    HtmlCss: TypographyApplicationTitleExampleHtmlCss,
  },
};
