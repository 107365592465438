import {
  Alert,
  Box,
  Snackbar,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { FunctionComponent, useState } from "react";

interface Props {
  label: string;
  color: string;
  secondaryLabel: string;
  arrangement?: "horizontal" | "vertical";
}

const Container = styled("div")(({ theme, ...props }: any) => ({
  margin: theme.spacing(2),
  width: props.arrangement === "horizontal" ? 130 : 180,
  height: props.arrangement === "horizontal" ? 40 : 110,
  display: "flex",
  flexDirection: props.arrangement === "horizontal" ? "row" : "column",
  justifyContent: "space-around",
  alignItems: "center",
  cursor: "pointer",
})) as any;

const TextContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const ColorContainer = styled("div")((props: any) => ({
  width: props.arrangement === "horizontal" ? 40 : 60,
  height: props.arrangement === "horizontal" ? 40 : 60,
  borderRadius: 4,
  backgroundColor: props.color,
  border: "1px solid black",
})) as any;

const ColorItem: FunctionComponent<Props> = ({
  label,
  color,
  secondaryLabel,
  arrangement,
}) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const theme = useTheme();

  return (
    <Container
      arrangement={arrangement}
      onClick={() => {
        navigator.clipboard.writeText(color);
        setAlertOpen(true);
      }}
    >
      <ColorContainer arrangement={arrangement} color={color} />
      <TextContainer>
        <Typography variant="caption">{label}</Typography>
        <Typography>{secondaryLabel}</Typography>
      </TextContainer>
      <Snackbar
        open={alertOpen}
        autoHideDuration={2000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <Alert severity="info" variant="filled">
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              color={theme.palette.getContrastText(theme.palette.info.main)}
            >
              Copied to clipboard:
            </Typography>
            <Box
              sx={{
                backgroundColor: color,
                color: theme.palette.getContrastText(color),
                marginLeft: 1,
              }}
            >
              {color}
            </Box>
          </Box>
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ColorItem;
