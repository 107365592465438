import { Footer, Icon } from "@advantest/ui-components";
import { Box, Typography, Link } from "@mui/material";

const StyleguideFooter = () => (
  <Footer
    socialMediaLinks={
      <Box
        sx={{
          width: "120px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Link
          href="https://www.facebook.com/advantestamericainc/"
          target="_blank"
          rel="noopener"
        >
          <Icon category="Action" label="Facebook" viewBox="0 0 26 26" />
        </Link>
        <Link
          href="https://twitter.com/Advantest_ATE"
          target="_blank"
          rel="noopener"
        >
          <Icon category="Action" label="Twitter" viewBox="0 0 26 26" />
        </Link>
      </Box>
    }
    legalLinks={
      <Box
        sx={{
          width: "300px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Link target="_blank" rel="noopener" sx={{ textDecoration: "none" }}>
          <Typography variant="subtitle2">Contact</Typography>
        </Link>
        <Link
          target="_blank"
          rel="noopener"
          sx={{ textDecoration: "none", typography: "subtitle2" }}
        >
          Terms of Use
        </Link>
        <Link
          target="_blank"
          rel="noopener"
          sx={{
            textDecoration: "none",
            typography: "subtitle2",
          }}
        >
          Privacy Policy
        </Link>
      </Box>
    }
  />
);

export default StyleguideFooter;
