import { Card, styled, useTheme } from "@mui/material";
import CodeExample from "../components/styleguideComponents/CodeExample";
import TypographyItem from "../components/styleguideComponents/TypographyItem";
import useColors from "../hooks/useColors";
import Layout from "../layout/Layout";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  width: 800,
}));

export const purchaseDemi = "https://www.myfonts.com/fonts/paratype/din-2014/";
export const downloadDemiPrepurchased =
  "https://weshare.advantest.com/vs/x/ZowXCw";
export const DIN2014DemiExplanation = `The whole application uses Rubik font, except the application title, that uses DIN 2014 demi, and a licensed package with instructions can be downloaded from weshare: ${downloadDemiPrepurchased}. If you don't have access, please contact your contact person for the package. This license is limited to <10000 pageloads/month and licensed for Advantest Corporation, so you might need to purchase it for your application from MyFonts: ${purchaseDemi} (DIN 2014 DEMI).`;

const Container = styled("div")(({ theme }) => ({ margin: theme.spacing(2) }));

const Typography = () => {
  const colors = useColors();

  return (
    <Layout>
      <Container>
        <StyledCard>
          <CodeExample
            defaultOpen="HtmlCss"
            label="TypographyMain"
            description={`Include the following code in the <head/> section of your index.html in order to load the 'Rubik
             Light' and 'Rubik Regular' font from Google Fonts. ${DIN2014DemiExplanation}`}
            linksAtTheEnd={[
              <a
                rel="noreferrer"
                target="_blank"
                href="https://fonts.google.com/specimen/Rubik#standard-styles"
              >
                Rubik font (Google Fonts)
              </a>,
              <a rel="noreferrer" target="_blank" href={purchaseDemi}>
                Purchase DIN 2014 demi font (MyFonts)
              </a>,

              <a
                rel="noreferrer"
                target="_blank"
                href={downloadDemiPrepurchased}
              >
                Download DIN 2014 demi licensed for Advantest Corporation
              </a>,
            ]}
          />
          <TypographyItem
            variant="subtitle1"
            label="Application Title in the header, below the Advantest logo, DIN 2014 Demi font"
            colorLabel="black"
            color={colors.black}
            spacing="18"
            isAbsolute
            codeExampleLabel="ApplicationTitle"
          />
          <TypographyItem
            variant="h1"
            label="Title 1"
            colorLabel="grey4"
            color={colors.grey[600]}
            spacing="1.802"
            codeExampleLabel="TypographyH1"
          />
          <TypographyItem
            variant="h2"
            label="Title 2"
            colorLabel="grey4"
            color={colors.grey[600]}
            spacing="1.602"
            codeExampleLabel="TypographyH2"
          />
          <TypographyItem
            variant="h3"
            label="Title 3"
            colorLabel="grey4"
            color={colors.grey[600]}
            spacing="1.424"
            codeExampleLabel="TypographyH3"
          />
          <TypographyItem
            variant="h4"
            label="TITLE 4"
            colorLabel="grey4"
            color={colors.grey[600]}
            spacing="1.266"
            codeExampleLabel="TypographyH4"
          />
          <TypographyItem
            variant="h5"
            label="TITLE 5"
            colorLabel="grey4"
            color={colors.grey[600]}
            spacing="1.125"
            codeExampleLabel="TypographyH5"
          />
          <TypographyItem
            variant="body1"
            label="Body 1"
            colorLabel="grey4"
            color={colors.grey[600]}
            spacing="1"
            codeExampleLabel="TypographyBody1"
          />
          <TypographyItem
            variant="body2"
            label="Body 2 - Only suitable on white background"
            colorLabel="grey3"
            color={colors.grey[500]}
            spacing="1"
            codeExampleLabel="TypographyBody2"
          />
          <TypographyItem
            variant="caption"
            label="Caption"
            colorLabel="grey4"
            color={colors.grey[600]}
            spacing="0.889"
            codeExampleLabel="TypographyCaption"
          />
        </StyledCard>
      </Container>
    </Layout>
  );
};

export default Typography;
