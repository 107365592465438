const TypographyCaptionExampleHtmlCss = `
<style>
  span {
    margin: 0;
    color: #263238;
    font-size: 0.889rem;
    font-family: Rubik,sans-serif;
    font-weight: 300;
    line-height: 1.66;
    letter-spacing: 0;
  }
</style>
<span>
Title1
</span>`;

export default TypographyCaptionExampleHtmlCss;
