import { useState } from "react";
import { styled, Snackbar, Alert } from "@mui/material";

import CodeExample from "./CodeExample";

const StaticAlertsContainer = styled("div")({
  height: 250,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
});

const Container = styled("div")({
  width: 700,
  display: "flex",
  flexDirection: "column",
});

const InfoExample = () => {
  const [infoOpen, setInfoOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  return (
    <Container>
      <StaticAlertsContainer>
        <Alert
          severity="info"
          variant="filled"
          onClick={() => setInfoOpen(true)}
          style={{ cursor: "pointer" }}
        >
          This is an info message
        </Alert>
        <Alert
          severity="success"
          variant="filled"
          onClick={() => setSuccessOpen(true)}
          style={{ cursor: "pointer" }}
        >
          This is a success message
        </Alert>
        <Alert
          severity="warning"
          variant="filled"
          onClick={() => setWarningOpen(true)}
          style={{ cursor: "pointer" }}
        >
          This is a warning message
        </Alert>
        <Alert
          severity="error"
          variant="filled"
          onClick={() => setErrorOpen(true)}
          style={{ cursor: "pointer" }}
        >
          This is an error message
        </Alert>
      </StaticAlertsContainer>
      <Snackbar
        open={infoOpen}
        autoHideDuration={2000}
        onClose={() => setInfoOpen(false)}
      >
        <Alert severity="info" variant="filled">
          This is an info message
        </Alert>
      </Snackbar>
      <Snackbar
        open={successOpen}
        autoHideDuration={2000}
        onClose={() => setSuccessOpen(false)}
      >
        <Alert severity="success" variant="filled">
          This is a success message
        </Alert>
      </Snackbar>
      <Snackbar
        open={warningOpen}
        autoHideDuration={2000}
        onClose={() => setWarningOpen(false)}
      >
        <Alert severity="warning" variant="filled">
          This is a warning message
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorOpen}
        autoHideDuration={2000}
        onClose={() => setErrorOpen(false)}
      >
        <Alert severity="error" variant="filled">
          This is an error message
        </Alert>
      </Snackbar>
      <CodeExample label="Alert" />
    </Container>
  );
};

export default InfoExample;
