import { styled, Snackbar, Alert } from "@mui/material";
import { FunctionComponent, useState } from "react";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: 200,
  justifyContent: "space-around",
  margin: theme.spacing(4),
  cursor: "pointer",
}));

const SpaceSquare = styled("div")(({ theme, ...props }: any) => ({
  width: `${props.spaceSize}${props.isRelative ? "rem" : "px"}`,
  height: `${props.spaceSize}${props.isRelative ? "rem" : "px"}`,
  backgroundColor: theme.palette.grey[200],
})) as any;

interface Props {
  spaceSize: string;
  isRelative?: boolean;
}

const SpaceItem: FunctionComponent<Props> = ({ spaceSize, isRelative }) => {
  const [open, setOpen] = useState(false);
  return (
    <Container
      key={spaceSize}
      onClick={() => {
        navigator.clipboard.writeText(
          `${spaceSize}${isRelative ? "rem" : "px"}`
        );
        setOpen(true);
      }}
    >
      <SpaceSquare spaceSize={spaceSize} isRelative={isRelative} />
      {spaceSize}
      {isRelative ? "rem" : "px"}
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <Alert severity="info" variant="filled">
          Copied {spaceSize}
          {isRelative ? "rem" : "px"} to clipboard.
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SpaceItem;
