const TypographyH4ExampleHtmlCss = `
<style>
  h4 {
    margin: 0;
    color: #263238;
    font-size: 1.266;
    font-family: Rubik,sans-serif;
    font-weight: 300;
    line-height: 1.235;
    letter-spacing: 0;
  }
</style>
<h4>
Title 4
</h4>`;

export default TypographyH4ExampleHtmlCss;
