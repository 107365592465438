import { FunctionComponent, useState } from "react";
import { Dimensions } from "../..";
import { StyledExpandableButton } from "./CodeExample";
import DimensionsExplanationButton from "./DimensionsExplanationCard";

interface Props {
  dimensions: Dimensions;
}

const DimensionsExample: FunctionComponent<Props> = ({ dimensions }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <>
      <StyledExpandableButton
        style={{ marginBottom: 24 }}
        onClick={() => setIsActive(!isActive)}
        color={isActive ? "secondary" : "primary"}
        variant="contained"
      >
        Dimensions
      </StyledExpandableButton>

      {isActive && <DimensionsExplanationButton dimensions={dimensions} />}
    </>
  );
};

export default DimensionsExample;
