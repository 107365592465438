import React from "react";
import FooterExample from "../../components/styleguideComponents/FooterExample";
import Layout from "../../layout/Layout";
import { ComponentContainer } from "./";

const Footers = () => (
  <Layout>
    <div style={{ margin: 16 }}>
      <ComponentContainer>
        <FooterExample />
      </ComponentContainer>
    </div>
  </Layout>
);

export default Footers;
