import { Typography } from "@mui/material";
import ReleaseNote from "./ReleaseNote";

export const LATEST = "4.0.0";

const ReleaseNotes = () => {
  return (
    <div style={{ marginTop: "40px" }}>
      {" "}
      <Typography variant="h2">Release notes</Typography>
      <ReleaseNote
        version={LATEST}
        initialIsOpen
        releaseDate="2022.05.13"
        details={[
          "update to material ui v5",
          "update to @advantest/ui-components v4.0.0",
          "decreased header height to 82px",
        ]}
      />
      <ReleaseNote
        version="0.1.2"
        releaseDate="2021.06.07"
        details={["add DIN 2014 Demi font to typography examples"]}
      />
      <ReleaseNote
        version="0.1.0"
        releaseDate="2021.06.01"
        details={[
          "add DIN 2014 Demi as font for application title in the header",
          "add 'Logos' page",
          "include 400 fontWeight version of Rubik in index.html, to avoid usage of faux-bold",
          "improved scaling of typography based on this site: https://type-scale.com/",
          "darkened typography to achieve minimum 4.5 contrast to the 'background' color, based on this site: https://contrast-ratio.com/",
          "introduced relative font sizes (rem) instead of absolute font sizes (px) to improve accessibility",
        ]}
      />
      <ReleaseNote
        version="0.0.1"
        releaseDate="2021.04.30"
        details={[
          "add 'dimensions' functionality: clicking on dimensions button below an example displays distance/color/css tips information",
          "add dimensions information to Header and Footer ",
        ]}
      />
    </div>
  );
};

export default ReleaseNotes;
