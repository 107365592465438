import React from "react";
import SpaceItem from "../components/styleguideComponents/SpaceItem";
import { VerticalContainer } from "../containers";
import Layout from "../layout/Layout";

const spacesArray = ["8", "16", "24", "32", "40"];

const Spaces = () => {
  return (
    <Layout>
      <div style={{ margin: 16 }}>
        <VerticalContainer>
          {spacesArray.map((spaceSize) => (
            <SpaceItem key={spaceSize} spaceSize={spaceSize} />
          ))}
        </VerticalContainer>
      </div>
    </Layout>
  );
};

export default Spaces;
