const TypographyH2ExampleHtmlCss = `
<style>
  h2 {
    margin: 0;
    color: #263238;
    font-size: 1.802rem;
    font-family: Rubik,sans-serif;
    font-weight: 300;
    line-height: 1.167;
    letter-spacing: 0;
  }
</style>
<h2>
  Title 2
</h2>`;

export default TypographyH2ExampleHtmlCss;
