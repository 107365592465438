import { useTheme } from "@mui/material";
import CodeExample from "../CodeExample";
import DimensionsExample from "../DimensionsExample";
import StyleguideFooter from "../../StyleguideFooter";
import getFooterDimensions from "../../../getFooterDimensions";

const FooterExamplePerDevice = () => {
  const theme = useTheme();
  return (
    <div style={{ marginBottom: 40 }}>
      <div
        style={{
          width: "1200px",
          border: "1px solid black",
          height: 250,
          marginBottom: 24,
        }}
      >
        <StyleguideFooter />
      </div>
      <CodeExample label="Footer" />
      <DimensionsExample dimensions={getFooterDimensions()} />
    </div>
  );
};

export default FooterExamplePerDevice;
