import { FunctionComponent } from "react";
import {
  Box,
  Button,
  List,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { ROUTES } from "../Routes";
import { getFooterHeight, getHeaderHeight } from "@advantest/ui-components";
import StyleguideHeader from "../components/StyleguideHeader";
import StyleguideFooter from "../components/StyleguideFooter";

const DRAWER_WIDTH = 234;

const Container = styled("div")(({ theme }) => ({
  height: "100%",
  background: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const NoHeaderContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
});

const ListContainer = styled("div")((props: any) => ({
  position: "absolute",
  height: 500,
  width: DRAWER_WIDTH,
  top: props.top,
}));

const StyledList = styled(List)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

const NoDrawerContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: `calc(100vw - ${DRAWER_WIDTH}px)`,
});

const StyledButton = styled(Button)(({ theme, ...props }) => ({
  backgroundColor: (props as any).active ? theme.palette.grey[200] : "inherit",
  "&:hover": { backgroundColor: theme.palette.grey[100] },
  width: 200,
})) as any;

const Layout: FunctionComponent = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const moreThanLaptop = useMediaQuery(theme.breakpoints.up("md"), {
    noSsr: true,
  });
  const moreThanMobile = useMediaQuery(theme.breakpoints.up("xs"), {
    noSsr: true,
  });

  return (
    <Container>
      <StyleguideHeader />
      <NoHeaderContainer>
        <Box
          sx={{
            width: DRAWER_WIDTH,
            minHeight: 500,
            height: 500,
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            borderRight: `1px solid ${theme.palette.grey[300]}`,
            background: theme.palette.background.paper,
          }}
        >
          <ListContainer>
            <StyledList>
              <StyledButton
                active={location.pathname === ROUTES.root}
                onClick={() => history.push(ROUTES.root)}
                {...(location.pathname === ROUTES.root
                  ? { "aria-current": "page" }
                  : {})}
              >
                <Typography variant="h2">Home</Typography>
              </StyledButton>
              <StyledButton
                active={location.pathname === ROUTES.colors}
                onClick={() => history.push(ROUTES.colors)}
                {...(location.pathname === ROUTES.colors
                  ? { "aria-current": "page" }
                  : {})}
              >
                <Typography variant="h2">Colors</Typography>
              </StyledButton>
              <StyledButton
                active={location.pathname === ROUTES.spaces}
                onClick={() => history.push(ROUTES.spaces)}
                {...(location.pathname === ROUTES.spaces
                  ? { "aria-current": "page" }
                  : {})}
              >
                <Typography variant="h2">Spaces</Typography>
              </StyledButton>
              <StyledButton
                active={location.pathname === ROUTES.icons}
                onClick={() => history.push(ROUTES.icons)}
                {...(location.pathname === ROUTES.icons
                  ? { "aria-current": "page" }
                  : {})}
              >
                <Typography variant="h2">Icons</Typography>
              </StyledButton>
              <StyledButton
                active={location.pathname === ROUTES.newIcons}
                onClick={() => history.push(ROUTES.newIcons)}
                {...(location.pathname === ROUTES.newIcons
                  ? { "aria-current": "page" }
                  : {})}
              >
                <Typography variant="h2">New Icons</Typography>
              </StyledButton>
              <StyledButton
                active={location.pathname === ROUTES.logos}
                onClick={() => history.push(ROUTES.logos)}
                {...(location.pathname === ROUTES.logos
                  ? { "aria-current": "page" }
                  : {})}
              >
                <Typography variant="h2">Logos</Typography>
              </StyledButton>
              <StyledButton
                active={location.pathname === ROUTES.typography}
                onClick={() => history.push(ROUTES.typography)}
                {...(location.pathname === ROUTES.typography
                  ? { "aria-current": "page" }
                  : {})}
              >
                <Typography variant="h2">Typography</Typography>
              </StyledButton>
              <StyledButton
                {...(location.pathname === ROUTES.components
                  ? { "aria-current": "page" }
                  : {})}
                active={location.pathname.includes(ROUTES.components)}
                onClick={() => history.push(ROUTES.components)}
              >
                <Typography variant="h2">Components</Typography>
              </StyledButton>
            </StyledList>
          </ListContainer>
        </Box>
        <NoDrawerContainer>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              boxSizing: "border-box",
              minHeight: `calc(100vh - ${getHeaderHeight(
                false,
                !moreThanMobile
              )}px - ${getFooterHeight(moreThanLaptop)}px)`,
            }}
          >
            {children}
          </Box>
        </NoDrawerContainer>
      </NoHeaderContainer>
      <StyleguideFooter />
    </Container>
  );
};

export default Layout;
