import React from "react";
import ReactDOM from "react-dom";
import "./App.css";
import Routes from "./Routes";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material";
import { Auth0Provider } from "@auth0/auth0-react";
import { theme } from "@advantest/ui-components";

export type EnvironmentType = "development" | "test" | "production";

interface Dimension {
  value: string | number;
  description: string;
}
export type Dimensions = Record<string, Dimension>;

const testUrl = "https://styleguide-test.advantest.com";
const prodUrl = "https://styleguide.advantest.com";

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
      redirectUri={process.env.REACT_APP_ORIGIN || ""}
    >
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
