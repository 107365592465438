import ColorItem from "../components/styleguideComponents/ColorItem";
import { HorizontalContainer } from "../containers";
import useColors from "../hooks/useColors";
import Layout from "../layout/Layout";
//
const Colors = () => {
  const colors = useColors();

  return (
    <Layout>
      <div style={{ margin: 16 }}>
        <HorizontalContainer>
          <ColorItem
            label="white"
            secondaryLabel={colors.white}
            color={colors.white}
          />
          <ColorItem
            label="primary"
            secondaryLabel={colors.primary}
            color={colors.primary}
          />
          <ColorItem
            label="primary_hover_bg"
            secondaryLabel={colors.primary_hover_bg}
            color={colors.primary_hover_bg}
          />
          <ColorItem
            label="secondary"
            secondaryLabel={colors.secondary}
            color={colors.secondary}
          />
          <ColorItem
            label="secondary_hover_bg"
            secondaryLabel={colors.secondary_hover_bg}
            color={colors.secondary_hover_bg}
          />
          <ColorItem
            label="brand"
            secondaryLabel={colors.brand}
            color={colors.brand}
          />
          <ColorItem
            label="success"
            secondaryLabel={colors.success}
            color={colors.success}
          />
          <ColorItem
            label="warning"
            secondaryLabel={colors.warning}
            color={colors.warning}
          />
          <ColorItem
            label="error"
            secondaryLabel={colors.error}
            color={colors.error}
          />
          <ColorItem
            label="background"
            secondaryLabel={colors.background}
            color={colors.background}
          />
          <ColorItem
            label="black"
            secondaryLabel={colors.black}
            color={colors.black}
          />
          <ColorItem
            label="grey1"
            secondaryLabel={colors.grey[200]}
            color={colors.grey[200]}
          />
          <ColorItem
            label="grey2"
            secondaryLabel={colors.grey[300]}
            color={colors.grey[300]}
          />
          <ColorItem
            label="grey3"
            secondaryLabel={colors.grey[500]}
            color={colors.grey[500]}
          />
          <ColorItem
            label="grey4"
            secondaryLabel={colors.grey[600]}
            color={colors.grey[600]}
          />
        </HorizontalContainer>
      </div>
    </Layout>
  );
};

export default Colors;
