import Layout from "../layout/Layout";
import { HorizontalContainer, VerticalContainer } from "../containers";
import { styled, Typography } from "@mui/material";
import ApplicationLogo from "../components/styleguideComponents/ApplicationLogo";

const LOGO_SIZE = 100;

const Container = styled("div")(({ theme }) => ({
  margin: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
  height: LOGO_SIZE + 50,
  width: LOGO_SIZE,
}));

const Logos = () => (
  <Layout>
    <div style={{ margin: 16 }}>
      <VerticalContainer>
        <HorizontalContainer>
          <Container>
            <img
              src="/acs_logo.svg"
              alt="acs_logo"
              style={{ height: LOGO_SIZE, width: LOGO_SIZE }}
            />
            <Typography>ACS logo</Typography>
          </Container>
          <Container>
            <img
              src="/ADVANTEST_logo_original.svg"
              alt="advantest_logo"
              style={{ height: LOGO_SIZE, width: LOGO_SIZE }}
            />
            <Typography>Advantest logo</Typography>
          </Container>
        </HorizontalContainer>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <ApplicationLogo
            applicationFolderName="acs-adaptive-probe-card-cleaning"
            logoBaseName="AcsAdaptiveProbeCardCleaning"
            nrOfLogos={4}
          />
          <ApplicationLogo
            applicationFolderName="acs-application-store"
            logoBaseName="AcsApplicationStore"
            nrOfLogos={3}
          />
          <ApplicationLogo
            applicationFolderName="acs-container-hub"
            logoBaseName="AcsContainerHub"
            nrOfLogos={2}
          />
          <ApplicationLogo
            applicationFolderName="acs-dynamic-parametric-test"
            logoBaseName="AcsDynamicParametricTest"
            nrOfLogos={4}
          />
          <ApplicationLogo
            applicationFolderName="acs-edge"
            logoBaseName="AcsEdge"
            nrOfLogos={2}
          />
          <ApplicationLogo
            applicationFolderName="acs-engineering-ai-studio"
            logoBaseName="AcsEngineeringAIStudio"
            nrOfLogos={4}
          />
          <ApplicationLogo
            applicationFolderName="advantest-dojo"
            logoBaseName="AdvantestDojo"
            nrOfLogos={2}
          />
          <ApplicationLogo
            applicationFolderName="acs-smart-insight"
            logoBaseName="AcsSmartInsight"
            nrOfLogos={2}
          />
          <ApplicationLogo
            applicationFolderName="acs-te-cloud"
            logoBaseName="AcsTeCloud"
            nrOfLogos={2}
          />
          <ApplicationLogo
            applicationFolderName="advantest-cloud-solutions"
            logoBaseName="AdvantestCloudSolutions"
            nrOfLogos={4}
          />
        </div>
      </VerticalContainer>
    </div>
  </Layout>
);

export default Logos;
