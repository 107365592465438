import { styled } from "@mui/material";

export const HorizontalContainer = styled("div")({
  maxWidth: 2000,
  display: "flex",
  flexWrap: "wrap",
});

export const VerticalContainer = styled("div")({
  maxWidth: 2000,
  display: "flex",
  flexDirection: "column",
});
