import { useTheme } from "@mui/material";

const useColors = () => {
  const theme = useTheme();

  return {
    grey: theme.palette.grey,
    black: "#000000",
    white: "#ffffff",
    primary: theme.palette.primary.main,
    primary_hover_bg: "#0060C9",
    secondary_hover_bg: "#6D7D85",
    secondary: theme.palette.secondary.main,
    brand: "#A00145",
    success: theme.palette.success.main,
    warning: theme.palette.warning.main,
    error: theme.palette.error.main,
    background: theme.palette.background.default,
  };
};

export default useColors;
