const TypographyBody2ExampleHtmlCss = `
<style>
  p {
    margin: 0;
    color: #607D8B;
    font-size: 1rem;
    font-family: Rubik,sans-serif;
    font-weight: 300;
    line-height: 1.43;
    letter-spacing: 0;
  }
</style>
<p>
Body 2 - Only suitable on white background
</p>`;

export default TypographyBody2ExampleHtmlCss;
