import { isHorizontalFooter, getFooterHeight } from "@advantest/ui-components";
import { Dimensions } from ".";

const getFooterDimensions = (): Dimensions => ({
  footerContainer: {
    value: "<footer/>",
    description: `This <footer/> element contains all the 3 main elements of the footer: legalLinksContainer, center and socialMediaContainerWrapper. The center element is a simple <div/> element. The purpose of this footer container is to align these three elements based. Set the following css properties on the footer as the following:
       {\n
          display: flex;\n
          flex-direction: ${isHorizontalFooter(true) ? "row" : "column"};\n
          justify-content: ${
            isHorizontalFooter(true) ? "space-between" : "space-around"
          };\n
          align-items: "center";
          height: ${getFooterHeight(
            true
          )}}. Please check the developer console for further assistance.`,
  },
  legalLinksContainerWidth: {
    value: "300px !important",
    description: `The links are distributed at an equal distance in 300px width. This layout can be achived by setting the following css properties on this 300px width container: \n
        {\n
          display: flex;\n
          flex-direction: row;\n
          justify-content: space-around;\n
          align-items: center;\n
        }. Please check the developer console for further assistance.`,
  },
  socialMediaContainerWidth: {
    value: "120px !important",
    description: `The social media icons are distributed at an equal distance in 120px. This layout can be achived by setting the following css properties on this 120px width container: \n
        {\n
          display: flex;\n
          flex-direction: row;\n
          justify-content: space-around;\n
          align-items: center;\n
        }. Please check the developer console for further assistance.`,
  },
  socialMediaContainerWrapperWidth: {
    value: "300px !important",
    description: `This 300px wide container wraps the 120px wide social media container. It's purpose is to be able to center that horizontally. This can be achieved by setting the following css properties on this 300px wide container: \n
        {\n
          display: flex;\n
          flex-direction: row;\n
          justify-content: ${
            isHorizontalFooter(true) ? "flex-end" : "center"
          };\n
          align-items: center;\n
        }. Please check the developer console for further assistance.`,
  },
  footerHeight: {
    value: `${getFooterHeight(true)}px`,
    description: `The height of the footer`,
  },
  socialMediaIconViewBox: {
    value: "0 0 26 26",
    description: `To get the desired size of the social media icon, this viewbox property should be set on the <svg/> component, together with using the corresponding path (click on the respective icon in the icons section to copy the path to clipboard). For further assistance please check the developer console.`,
  },
});

export default getFooterDimensions;
