import CodeExample from "./CodeExample";
import CustomTextField from "../CustomTextField";

const TextFieldExample = () => {
  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <CustomTextField />
      </div>
      <div style={{ marginBottom: 16 }}>
        <CustomTextField placeholder="This is a placeholder" />
      </div>
      <div style={{ marginBottom: 16 }}>
        <CustomTextField label="First name" />
      </div>
      <div style={{ marginBottom: 16 }}>
        <CustomTextField label="First name" required />
      </div>
      <div style={{ marginBottom: 16 }}>
        <CustomTextField label="First name" disabled />
      </div>
      <div style={{ marginBottom: 16 }}>
        <CustomTextField label="Phone number" error="Invalid phone format" />
      </div>
      <div style={{ marginBottom: 16 }}>
        <CustomTextField starticon={{ category: "Menu", name: "Cart" }} />
      </div>
      <div style={{ marginBottom: 16 }}>
        <CustomTextField endicon={{ category: "Menu", name: "Global" }} />
      </div>
      <div style={{ marginBottom: 16 }}>
        <CustomTextField rows={6} label="Multiline text input" multi />
      </div>
      <CodeExample label="TextField" />
    </div>
  );
};

export default TextFieldExample;
