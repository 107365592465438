import React from "react";
import ButtonsExample from "../../components/styleguideComponents/ButtonsExample";
import Layout from "../../layout/Layout";
import { ComponentContainer } from "./";

const Buttons = () => (
  <Layout>
    <div style={{ margin: 16 }}>
      <ComponentContainer>
        <ButtonsExample />
      </ComponentContainer>
    </div>
  </Layout>
);

export default Buttons;
