import { Icon } from "@advantest/ui-components";
import { TextField } from "@mui/material";
import { FunctionComponent } from "react";
import { CustomizableStyleguideIconProps } from "./icons/MainIconExample";

interface StyleguideTextFieldIcon {
  category: string;
  name: string;
  props?: CustomizableStyleguideIconProps;
}

interface Props {
  label?: string;
  required?: boolean;
  placeholder?: string;
  starticon?: StyleguideTextFieldIcon;
  endicon?: StyleguideTextFieldIcon;
  rows?: number;
  position?: string;
  disabled?: boolean;
  error?: string;
  multi?: boolean;
}

const CustomTextField: FunctionComponent<Props> = ({
  label,
  required,
  placeholder,
  disabled,
  error,
  multi,
  starticon,
  endicon,
}) => {
  return (
    <TextField
      disabled={disabled}
      placeholder={placeholder}
      required={required}
      error={!!error}
      helperText={error}
      label={label}
      multiline={!!multi}
      inputProps={{
        startAdornMent: (
          <Icon
          // type={starticon.}
          // viewBox?= string;
          // d?= string | string[];
          // fill?= string;
          // height?= string;
          // width?= string;
          // category?= string;
          // label?= string;
          />
        ),
      }}
    />
  );
};

export default CustomTextField;
