import { Alert, Snackbar, styled, Typography } from "@mui/material";
import { FunctionComponent, useState } from "react";
import { Icon } from "@advantest/ui-components";

const ICON_ITEM_WIDTH = 150;

interface Props {
  label: string;
  category: "Action" | "Menu" | "SI";
}

const Container = styled("div")(({ theme }) => ({
  margin: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
  padding: 8,
}));

const LabelContainer = styled("div")({
  width: ICON_ITEM_WIDTH,
  display: "flex",
  justifyContent: "center",
});

const IconContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 28,
})) as any;

const IconItem: FunctionComponent<Props> = ({ label, category }) => {
  const [alertOpen, setAlertOpen] = useState(false);

  return (
    <Container>
      <IconContainer>
        <Icon category={category} label={label} />
      </IconContainer>
      <LabelContainer>
        <Typography>{label}</Typography>
      </LabelContainer>
      <Snackbar
        open={alertOpen}
        autoHideDuration={2000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert severity="info" variant="filled">
          Copied code of {label} to clipboard.
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default IconItem;
