import { styled, Typography } from "@mui/material";
import MinifiedHeaderExamplePerDevice from "./MinifiedHeaderExamplePerDevice";

const Container = styled("div")(() => ({
  width: 1000,
}));

const HeaderExample = () => {
  return (
    <Container>
      <Typography variant="h2" style={{ marginBottom: 24 }}>
        Minified header
      </Typography>

      <MinifiedHeaderExamplePerDevice />
    </Container>
  );
};

export default HeaderExample;
